"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.phoneFormatter = void 0;
var phoneFormatter = function (phone) {
    phone = phone.replace(/[^0-9]/g, '');
    if (phone.startsWith('+7')) {
        phone = phone.slice(2);
    }
    if (phone.startsWith('8') || phone.startsWith('7')) {
        phone = phone.slice(1);
    }
    return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, "+7($1)$2-$3$4");
};
exports.phoneFormatter = phoneFormatter;
