const trackEvent = ({category, action, label, value}) => {
  if (window && window.ga) {
    const args = [`gtm3.send`, `event`, category, action, label, value].filter(
      (i) => i !== void 0
    );
    window.ga.apply(null, args);
  }
};

export default trackEvent;
